import { defineStore } from "pinia";

export const useTripsStore = defineStore("trips", () => {

    const items = ref([]);

    const loadData = async (payload) => {
        await axios.get('/api/trips', { params: payload })
            .then((response) => {
                items.value = response.data;
            })
    };

    return {
        items,
        loadData,
    }
});
