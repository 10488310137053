import { defineStore } from "pinia";
import { useCartStore } from "@/Stores/Cart.js";

export const useCartBaggagesStore = defineStore("CartBaggages", () => {

    const cartBaggages = ref({});

    const loadData = async () => {
        await axios.get('/api/cart/baggage',)
            .then((response) => {
                cartBaggages.value = response.data;
            })
    };

    const updateQty = (payload) => {
        return axios.post('/api/cart/baggage', payload)
            .then((response) => {
                useCartStore().reload();
            })
    };

    const items = computed(() => {
        return cartBaggages.value.items ?? [];
    });

    const note = computed(() => {
        return cartBaggages.value.note ?? null
    });

    return {
        note,
        items,
        loadData,
        updateQty,
    }
});
