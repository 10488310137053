
const visitOptions = {
    method: 'get',
    data: {},
    headers: {},
    onBefore: () => { },
    onStart: () => { },
    onFinish: () => { },
    onSuccess: () => { },
    onError: () => { },
};

const proccesing = ref(false);

export class route {

    visit(href, options = {}) {
        if (options.onBefore) {
            options.onBefore();
        }

        if (options.onStart) {
            options.onStart();
        }
        proccesing.value = true;

        axios({
            method: options.method,
            url: href,
            data: options.data,
            headers: options.headers
        })
            .then((response) => {
                if (options.onSuccess) {
                    options.onSuccess(response.data);
                }
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                if (options.onError) {
                    options.onError(error);
                }
                return Promise.reject(error);
            })
            .finally(() => {
                if (options.onFinish) {
                    options.onFinish();
                }
                proccesing.value = false;
            });
    };

    get(url, payload = {}, options) {
        const params = new URLSearchParams(payload);
        url = url + '?' + params.toString();
        return this.visit(url, { ...options, method: 'get' })
    };
    post(url, data, options) {
        return this.visit(url, { ...options, method: 'post', data })
    };
    put(url, data, options) {
        return this.visit(url, { ...options, method: 'put', data })
    };
    delete(url, options) {
        return this.visit(url, { ...options, method: 'delete' })
    }
}

export const useAxios = new route();