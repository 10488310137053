import { defineStore } from "pinia";

export const useCheckoutStore = defineStore("checkout", () => {

    const payment_methods = ref([]);

    const type = ref(null);

    const countries = ref([]);

    const loadData = () => {
        return axios.get('/api/checkout')
            .then((response) => {
                payment_methods.value = response.data.payment_methods;
                type.value = response.data.type;
                countries.value = response.data.countries;
            });
    };
    return {
        payment_methods,
        type,
        countries,
        loadData,
    }
});
