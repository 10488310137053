import { defineStore } from "pinia";
import { useAxios } from "@/Helpers/useAxios.js";

export const useAlternativeDatePriceStore = defineStore("alternativeDatePrice", () => {

    const router = useRouter();
    const route = useRoute();

    const params = reactive({
        startDate: "",
        endDate: "",
        adults: 2,
        children: [],
        region: "",
        regionGroup: "",
        locationName: "",
        location: "",
        productType: "",
        departureAirport: "",
        page: 1,
        filter_serviceType: [],
        debug: true,
        sort: "price;asc",
        alternativeDateRange: 7,
    });

    const loading = ref(true);
    const items = ref([]);

    const loadData = () => {
        useAxios.get('/api/alternative-dates-price', params, {
            onBefore: () => {
                loading.value = true
            },
            onSuccess: (response) => {
                items.value = response;
                loading.value = false;
            },
        });
    };

    // next 
    const next = () => {
        items.value.next_page_url && loadData({ url: items.value.next_page_url });
    }

    const previous = () => {
        items.value.prev_page_url && loadData({ url: items.value.prev_page_url });
    }

    // get max value from items
    const maxValue = computed(() => {
        return Math.max(...items.value.map(item => item.price));
    });

    // get min value from items except 0
    const minValue = computed(() => {

        const _items = items.value?.filter(object => {
            return object.price > 0;
        });

        return Math.min(..._items.map(item => item.price));
    });

    onMounted(() => {
        router.isReady().then(async () => {
            await _.assign(params, route.query);

            const width = window.innerWidth;

            if (width < 768) {
                params.alternativeDateRange = 3;
            }
            else if (width < 992) {
                params.alternativeDateRange = 5;
            }

            loadData();
        });
    });

    return {
        items,
        loading,
        maxValue,
        minValue,
        loadData,
    }
});
