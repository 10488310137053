import { createI18n } from "vue-i18n";
import moment from "moment/min/moment-with-locales";
import sl from "@/../lang/sl-SI.json";
import hr from "@/../lang/hr-HR.json";

moment.locale(document.documentElement.lang);

export default createI18n({
    warnHtmlMessage: false,
    numberFormats: {
        sl: {
            currency: {
                style: 'currency', currency: 'eur', notation: 'standard'
            },
        },
        hr: {
            currency: {
                style: 'currency', currency: 'eur', notation: 'standard'
            },
        },
    },
    datetimeFormats: {
        sl: {
            short: {
                year: "numeric",
                month: "short",
                day: "numeric",
            },
            long: {
                year: "numeric",
                month: "short",
                day: "numeric",
                weekday: "short",
                hour: "numeric",
                minute: "numeric",
            },
        },
        hr: {
            short: {
                year: "numeric",
                month: "short",
                day: "numeric",
            },
            long: {
                year: "numeric",
                month: "short",
                day: "numeric",
                weekday: "short",
                hour: "numeric",
                minute: "numeric",
            },
        },
    },
    legacy: false,
    locale: document.documentElement.lang,
    pluralizationRules: {
        sl: customRule,
        hr: customRule,
    },
    messages: {
        sl,
        hr,
    },
});

function customRule(choice, choicesLength, orgRule) {
    if (choice < 3) {
        return choice;
    }
    return 3;
}
