import { defineStore } from "pinia";
import { useCartStore } from "@/Stores/Cart.js";

export const useCartInsuranceStore = defineStore("CartInsurance", () => {

    const item = ref({});

    const processing = ref(false);

    const loadData = async () => {
        await axios.get('/api/cart/insurance',)
            .then((response) => {
                item.value = response.data;
            });
    };

    const addAction = () => {
        processing.value = true;
        return axios.post('/api/cart/insurance')
            .then((response) => {
                useCartStore().reload();
                return response.data;
            })
            .finally(() => {
                processing.value = false;
            });
    };

    const removeAction = (hash) => {
        processing.value = true;
        return axios.delete('/api/cart/insurance/' + hash)
            .then((response) => {
                useCartStore().reload();
            })
            .finally(() => {
                processing.value = false;
            });
    };

    return {
        item,
        processing,
        loadData,
        addAction,
        removeAction
    }
});
