import { defineStore } from "pinia";

export const useFavoriteStore = defineStore("favorites", () => {

    const items = ref([]);

    const count = computed(() => {
        return items.value.length;
    })

    const setItemId = (item) => {
        return item.offer.gid + '-' + item.travelDate.startDate + '-' + item.travelDate.endDate + '-' + item.url + '-' + item.price.minimumPrice;
    }

    const isFavorite = (item) => {
        item.id = setItemId(item);
        return items.value.find((i) => i.id == item.id) != undefined;
    }
    const addFavorite = (item) => {
        items.value.push(item);
    }

    const removeFavorite = (item) => {
        items.value = items.value.filter((i) => i.id != item.id);
    }

    const toggleFavorite = (item) => {

        item.id = setItemId(item);

        if (isFavorite(item)) {
            removeFavorite(item);
        } else {
            addFavorite(item);
        }

        saveLocal();
    }
    const saveLocal = () => {
        localStorage.setItem('favorites', JSON.stringify(items.value));
    }
    const loadLocal = () => {
        items.value = JSON.parse(localStorage.getItem('favorites')) || [];
    }
    const clear = () => {
        items.value = [];
        saveLocal();
    }
    onMounted(() => {
        loadLocal();
    })
    return {
        isFavorite,
        toggleFavorite,
        clear,
        items,
        count
    }
});
