import { defineStore } from "pinia";
import { useAxios } from "@/Helpers/useAxios.js";

export const useOfferStore = defineStore("offer", () => {

    const router = useRouter();
    const route = useRoute();

    const params = reactive({
        gid: "",
        tourOperator: "",
        source: "",
        debug: true,
    });

    const loading = ref(true);

    const item = ref(null);

    const loadData = () => {
        useAxios.get('/api/offer', params, {
            onBefore: () => {
                loading.value = true
            },
            onSuccess: (response) => {
                item.value = response;
                loading.value = false;
            },
        });
    };

    onMounted(() => {
        router.isReady().then(async () => {
            _.assign(params, route.query);
            loadData();
        });
    });

    return {
        item,
        loading,
    }
});
