import { defineStore } from "pinia";

export const useCartOfferStore = defineStore("CartOffer", () => {

    const addToCart = async (payload) => {
        return await axios.post('/api/cart/offer', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    };

    return {
        addToCart,
    }
});
