import * as VueRouter from "vue-router";

import qs from 'qs';

const router = VueRouter.createRouter({

    // parse with comma
    parseQuery: (query) => {
        return qs.parse(query, {
            // comma: true,
        });
    },
    stringifyQuery: (query) => {
        return qs.stringify(query, {
            encode: false,
            //  arrayFormat: 'comma',
            //  commaRoundTrip: true,
        });
    },

    history: VueRouter.createWebHistory(import.meta.env.VITE_APP_ROUTE_WEB_HISTORY ?? ''),
    routes: [
        {
            path: "/",
            name: "search",
        },
        {
            path: "/ponudbe",
            name: "offers",
        },
        {
            path: "/ponudba",
            name: "offer",
        },
        {
            path: "/wishlist",
            name: "wishlist",
        },
        {
            path: "/cart/offer",
            name: "cart.offer",
        },
        {
            path: "/cart/baggage",
            name: "cart.baggage",
        },
        {
            path: "/cart/transfer",
            name: "cart.transfer",
        },
        {
            path: "/cart/trips",
            name: "cart.trips",
        },
        {
            path: "/checkout",
            name: "checkout",
        },
        {
            path: "/checkout/success",
            name: "checkout-success",
        },
        {
            path: "/checkout/payment",
            name: "checkout-payment",
        },
        {
            path: "/checkout/payment-success",
            name: "payment-success",
        },
        {
            path: "/checkout/payment-response",
            name: "payment-response",
        },
        {
            path: "/trips",
            name: "trips.index",
        },
        {
            path: "/trips/:id",
            name: "trips.show",
        },
    ],
})

export default router;

router.beforeEach((to, from) => {
    // get data from html
    const el = document.getElementById('app');

    const props = JSON.parse(el.dataset.page);

    // marge with query
    _.assign(to.query, props);
})