import { defineStore } from "pinia";
import { useCartStore } from "@/Stores/Cart.js";

export const useCartTripsStore = defineStore("CartTrips", () => {

    const cartTrips = ref([]);
    const proccesing = ref(false);

    const loadData = async () => {
        await axios.get('/api/cart/trips',)
            .then((response) => {
                cartTrips.value = response.data;
            })
    };

    const addItem = (payload) => {

        proccesing.value = true;

        return axios.post('/api/cart/trips', payload)
            .then((response) => {
                useCartStore().loadData();
                return response.data.message;
            })
            .finally(() => {
                proccesing.value = false;
            });
    };

    const items = computed(() => {
        return cartTrips.value.items || [];
    });

    return {
        proccesing,
        items,
        loadData,
        addItem,
    }
});
