import { defineStore } from "pinia";
import { useAxios } from "@/Helpers/useAxios.js";
import cloneDeep from 'lodash.clonedeep'
import { useAirportStore } from "@/Stores/Airport.js";
import { useFlightDatesStore } from "@/Stores/FlightDates.js";

export const useOfferDatesStore = defineStore("offerDates", () => {

    const router = useRouter();
    const route = useRoute();

    const loading = reactive({
        items: true,
        filter: true,
        loadMore: false,
        enableResults: false,
    });

    const pages = ref(0);
    const items = ref([]);
    const filters = ref([]);

    const params = reactive({
        gid: "",
        startDate: "",
        endDate: "",
        adults: 2,
        children: [],
        region: "",
        regionGroup: "",
        locationName: "",
        location: "",
        productType: "",
        departureAirport: "",
        page: 1,
        filter_roomType: [],
        filter_serviceType: [],
        filter_maxConnections: "",
        debug: true,
        sort: "price;asc",
    });

    const enableResetFilter = computed(() => {
        return params.filter_roomType.length || params.filter_serviceType.length;
    });

    const resetFilter = () => {
        params.filter_roomType = [];
        params.filter_serviceType = [];
        params.filter_maxConnections = "";
    };

    watch(() => [params.filter_roomType, params.filter_serviceType, params.filter_maxConnections], () => {
        params.page = 1;

        if (loading.items == false) {
            filterData();
        }
    }, { deep: true });

    watch(() => [params.regionGroup, params.region, params.location], () => {
        useAirportStore().loadData(params);
    }, { deep: true });

    watch(() => [params.departureAirport], () => {
        useFlightDatesStore().loadData(params);
    }, { deep: true });

    const filterData = () => {

        const options = {};

        options.onBefore = () => {
            loading.items = true;
        }

        options.onSuccess = (response) => {
            items.value = response.results;
            pages.value = response.totalPages;
        }

        options.onFinish = (response) => {
            loading.items = false;
        }

        fetchData(options);
    }

    const loadMore = () => {

        params.page++;

        const options = {};

        options.onBefore = () => {
            loading.loadMore = true;
        }

        options.onSuccess = (response) => {
            items.value.push(...Object.values(response.results));
        }

        options.onFinish = (response) => {
            loading.loadMore = false;
        }

        fetchData(options);
    }

    const search = () => {

        if (!params.startDate || !params.endDate) {
            return;
        }

        const options = {};

        options.onBefore = () => {
            loading.enableResults = loading.items = loading.filter = true;
            const query = cloneDeep(params);
            router.push({ path: '', query: query })
        }

        options.onSuccess = (response) => {
            items.value = response.results;
            pages.value = response.totalPages;
            filters.value = response.filters;
        }

        options.onFinish = (response) => {
            loading.filter = loading.items = false;
        }

        fetchData(options);
    }

    const fetchData = (options) => {
        useAxios.get('/api/dates', params, {
            onBefore: () => {
                options.onBefore();
            },
            onSuccess: (response) => {
                options.onSuccess(response);
            },
            onFinish: () => {
                options.onFinish();
            }
        });
    };

    onMounted(() => {
        router.isReady().then(async () => {
            _.assign(params, route.query);
            search();
        });
    });

    return {
        params,
        items,
        filters,
        enableResetFilter,
        loading,
        pages,
        loadMore,
        resetFilter,
        search,
    }
});