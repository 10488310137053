import { defineStore } from "pinia";
import { useCartStore } from "@/Stores/Cart.js";

export const useCartTransferStore = defineStore("CartTransfer", () => {

    const item = ref({});

    const loadData = async () => {
        await axios.get('/api/cart/transfer',)
            .then((response) => {
                item.value = response.data;
            })
    };

    const addItem = () => {
        return axios.post('/api/cart/transfer')
            .then((response) => {
                useCartStore().reload();
            });
    };

    return {
        item,
        loadData,
        addItem,
    }
});
