import { defineStore } from "pinia";
import { useAxios } from "@/Helpers/useAxios.js";
import { date } from "@/helpers/Utilities";

export const useFlightDatesStore = defineStore("flightDates", () => {

    const selectedDay = ref(null);

    const data = ref({
        departures: [],
        arrivals: [],
    });

    const firstDepartureDate = computed(() => {
        if (departureDatesFlat.value.length == 0) {
            return new Date();
        }

        return new Date(
            Math.min(
                ...departureDatesFlat.value.map(element => {
                    return new Date(element);
                }),
            ),
        );
    });

    const departureDatesFlat = computed(() => {
        return data.value.departures.map((item) => {
            return item.dates;
        }).flat();
    });

    const departureDates = computed(() => {
        if (selectedDay.value) {
            return [];
        }
        return departureDatesFlat.value;
    });

    const arrivalDates = computed(() => {
        if (selectedDay.value === null) {
            return [];
        }

        return data.value.arrivals.filter((item) => {
            return groups.value.includes(item.name);
        })
            .map((item) => {
                return item.dates;
            })
            .flat();
    });

    const groups = computed(() => {
        if (selectedDay.value === null) {
            return [];
        }

        return data.value.departures.filter(departures => {
            let departureDates = departures.dates.map(item => date(item, 'YYYY-MM-DD'));
            return departureDates.includes(date(selectedDay.value, 'YYYY-MM-DD'));
        }).map(obj => obj.name);
    });

    const loadData = (params) => {
        useAxios.get('/api/flight/dates', params, {
            onSuccess: (response) => {
                data.value = response;
            },
        });
    };

    return {
        firstDepartureDate,
        departureDates,
        arrivalDates,
        groups,
        selectedDay,
        loadData,
    }
});
